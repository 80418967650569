import Image1 from "./images/photo1.jpg";
import Image2 from "./images/photo2.jpg";
import Image3 from "./images/photo3.jpg";
import Image4 from "./images/photo4.jpg";
import Image5 from "./images/photo5.jpg";
// import Image6 from "./images/photo6.jpg";

export const ClientsInfo = [
    {
        src: `${Image1}`,
        alt: "Eltalom Schools",
        width: "100%",
        height: "100%",
        interval: 2000
    },
    {
        src: `${Image2}`,
        alt: "Al Sheraa Restaurant",
        width: "100%",
        height: "100%",
        interval: 2000
    },
    {
        src: `${Image3}`,
        alt: "Al Rajidi",
        width: "100%",
        height: "100%",
        interval: 2000
    },
    {
        src: `${Image4}`,
        alt: "Alaraby",
        width: "100%",
        height: "100%",
        interval: 2000
    },
    {
        src: `${Image5}`,
        alt: "ibis",
        width: "100%",
        height: "100%",
        interval: 2000
    },
    
];