const CommentsInfo = [
    {
        rating: "5",
        comment: " أشكركم على الخدمة الممتازة التي قدمتموها! كانت العملية سلسة واحترافية للغاية، والنتائج رائعة ",
        name: " سلمان الدوسري "
    },
    {
        rating: "5",
        comment: " أود أن أشكر فريقكم على الاستجابة السريعة والفعّالة لطلبي. كان العمل سريعاً ودقيقاً، والنتائج تتجاوز توقعاتي ",
        name: " فاطمة العتيبي "
    },
    {
        rating: "5",
        comment: " الخدمة التي قدمتموها كانت رائعة. كل زاوية في المنزل نظيفة تماماً وكأنها جديدة. شكراً لكم على الالتزام بالجودة والاهتمام بأدق التفاصيل ",
        name: " محمد الهاشمي "
    },
    {
        rating: "5",
        comment: " أعجبتني جداً دقة التزامكم بالمواعيد المحددة. كانت الخدمة في الوقت المحدد وبكل احترافية ",
        name: " سعيد الشهري "
    },
    {
        rating: "5",
        comment: " لقد كنت عميلًا متكرراً لشركتكم، وكل مرة أكون أكثر إعجاباً بالخدمة التي تقدمونها ",
        name: " نورة العنزي "
    },
    {
        rating: "5",
        comment: " الحقيقة أن الخدمة التي تلقيتها كانت ذات قيمة ممتازة مقابل المال ",
        name: " عبدالله السيف "
    }
];
export default CommentsInfo;