import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Register from "../components/registeration/register";




const CallUsPage = () => {
    return (
        <>
        <Header />
        <Register />
        <Footer />
        </>
    )
}
export default CallUsPage;