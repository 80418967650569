import React from "react";
import Header from "../components/header/header";
import Comments from "../components/comments/comments";
import Footer from "../components/footer/footer";




const CommentsPage = () => {
    return (
        <>
         <Header />
         <Comments />
         <Footer />
        </>
    )
}
export default CommentsPage;