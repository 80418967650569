export const Info = [
    {
        name: "اتصل بنا",
        path: "/call-us"
    },
    {
        name: "أخر التعليقات",
        path: "/comments"
    },
    {
        name: "عملاؤنا",
        path: "/clients"
    },
    {
        name: "سابقة الأعمال",
        path: "/previousWork"
    },
    {
        name: "خدماتنا",
        path: "/services"
    },
    {
        name: "الرئيسية",
        path: "/"
    },
];






